/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import './src/styles/global.css';

import { RouteUpdateArgs } from 'gatsby';
import sendAdbrixEvent from './src/lib/dfn-adbrix';

const pageEventMap: Record<string, string> = {
  '/reward-ad-plus': 'detail_page',
  '/publishers/monetize': 'monetize_page',
  '/contact#publishers': 'get_started_page',
};

const onRouteUpdate = ({ location }: RouteUpdateArgs) => {
  const eventName = pageEventMap[`${location.pathname}${location.hash}`];
  if (eventName) {
    sendAdbrixEvent(eventName);
  }
};

export { onRouteUpdate };
