/**
 * @see https://help.dfinery.io/hc/ko/articles/900005110403-%EB%94%94%ED%8C%8C%EC%9D%B4%EB%84%88%EB%A6%AC-%EC%95%A0%EB%93%9C%EB%B8%8C%EB%A6%AD%EC%8A%A4-%EC%97%B0%EB%8F%99%ED%95%98%EA%B8%B0-Web#toc32
 */
const sendAdbrixEvent = (eventName: string) => {
  if (window.adbrix) {
    window.adbrix.event.send(eventName);
  } else {
    console.warn('window.adbrix is undefined');
  }
};

export default sendAdbrixEvent;
